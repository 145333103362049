import userDetailsReducer from "./UserDetails";
import showSpinReducer from "./ShowSpin";
import isSavedReducer from "./IsSaved";
import { combineReducers } from "redux";
const allReducers = combineReducers({
  userDetails: userDetailsReducer,
  showSpin: showSpinReducer,
  isSaved: isSavedReducer,
});

export default allReducers;
