import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./Home";

const Users = lazy(() => import("./Users"));
const UserProfile = lazy(() => import("./UserProfile"));
const Login = lazy(() => import("./Login"));
const AccessDenied = lazy(() => import("./AccessDenied"));
const ChangePassword = lazy(() => import("./ChangePassword"));
const Appointments = lazy(() => import("./Appointments"));
const AppointmentDetails = lazy(() => import("./AppointmentDetails"));
const HealthRecord = lazy(() => import("./HealthRecord"));
const UserAccess = lazy(() => import("./UserAccess"));
const VitalSigns = lazy(() => import("./VitalSigns"));
const AppointmentStatus = lazy(() => import("./AppointmentStatus"));
const PaymentStatus = lazy(() => import("./PaymentStatus"));
const Consent = lazy(() => import("./Consent"));
const DocSchedule = lazy(() => import("./DocSchedule"));
const checkAuth = (props) => {
  let isLoggedIn = false;
  const tokenid = localStorage.getItem("tokenid");

  if (tokenid === null) {
    isLoggedIn = false;
  } else {
    isLoggedIn = true;
  }

  return isLoggedIn;
};
const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )
    }
  />
);
const Routes = () => {
  return (
    <Switch>
      <Route exact strict path="/" component={Home} render={() => <Home />} />
      <Route
        exact
        strict
        path="/home"
        component={Home}
        render={() => <Home />}
      />
      <Suspense
        fallback={
          <div className="text-center font-bold text-md">Loading page...</div>
        }
      >
        <AuthRoute
          exact
          strict
          path="/users"
          component={Users}
          render={() => <Users />}
        />

        <AuthRoute
          exact
          strict
          path="/userprofile"
          component={UserProfile}
          render={() => <UserProfile />}
        />
        <AuthRoute
          exact
          strict
          path="/changepassword"
          component={ChangePassword}
          render={() => <ChangePassword />}
        />
        <AuthRoute
          exact
          strict
          path="/appointments"
          component={Appointments}
          render={() => <Appointments />}
        />

        <AuthRoute
          exact
          strict
          path="/useraccess"
          component={UserAccess}
          render={() => <UserAccess />}
        />

        <AuthRoute
          exact
          strict
          path="/appointmentdetails"
          component={AppointmentDetails}
          render={() => <AppointmentDetails />}
        />
        <AuthRoute
          exact
          strict
          path="/healthrecord"
          component={HealthRecord}
          render={() => <HealthRecord />}
        />
        <AuthRoute
          exact
          strict
          path="/vitalsigns"
          component={VitalSigns}
          render={() => <VitalSigns />}
        />
        <AuthRoute
          exact
          strict
          path="/appointmentstatus"
          component={AppointmentStatus}
          render={() => <AppointmentStatus />}
        />

        <AuthRoute
          exact
          strict
          path="/paymentstatus"
          component={PaymentStatus}
          render={() => <PaymentStatus />}
        />
        <AuthRoute
          exact
          strict
          path="/docschedule"
          component={DocSchedule}
          render={() => <DocSchedule />}
        />
        <Route
          exact
          strict
          path="/login"
          component={Login}
          render={() => <Login />}
        />
        <Route
          exact
          strict
          path="/accessdenied"
          component={AccessDenied}
          render={() => <AccessDenied />}
        />
        <Route
          exact
          strict
          path="/consent/:Id"
          component={Consent}
          render={() => <Consent />}
        />
      </Suspense>
    </Switch>
  );
};

export default Routes;
