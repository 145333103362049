/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Layout, Menu, Modal, Avatar } from "antd";
import { withRouter } from "react-router-dom";
import Routes from "./components/Routes";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "./actions";
import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { GetListOfRecords } from "./components/Services";
const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;
const apiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DOMAIN_DEV
    : process.env.REACT_APP_DOMAIN_PROD;
const App = (props) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const showSpin = useSelector((state) => state.showSpin);
  const [imgSource, setImgSource] = useState("");
  const handleNavigatePage = (page) => {
    if (page === "/patientinformation") {
      localStorage.setItem("Id", "");
      localStorage.setItem("reportedby", "");
      props.history.push({
        pathname: "/consent",
        state: {},
      });
    } else {
      props.history.push(page);
    }
  };
  const handleLogOut = () => {
    Modal.confirm({
      title: "Logout system now?",
      icon: <ExclamationCircleOutlined />,

      onOk() {
        dispatch(updateUser({}));
        localStorage.removeItem("tokenid");
        props.history.push("/login");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const handleChangeUserProfile = () => {
    props.history.push({
      pathname: "/userprofile",
      state: { Id: userDetails.Id, hideBc: true },
    });
  };
  const handleChangePassword = () => {
    props.history.push({
      pathname: "/changepassword",
      state: { Id: userDetails.Id, hideBc: true },
    });
  };
  const handleGetUserDetails = async () => {
    if (userDetails.length === undefined) {
      let tokenid = localStorage.getItem("tokenid");

      if (tokenid !== null) {
        try {
          const res = await GetListOfRecords({
            param1: "getuserdetailsbytokenid",
            param2: tokenid,
          });
          if (res) {
            const x = {
              ...res[0],
              profilePic: apiUrl + "/getPatientProfilePicture?id=" + res[0].Id,
            };
            dispatch(updateUser(x));
            localStorage.removeItem("tokenid");
            localStorage.setItem("tokenid", res[0].tokenid);
            props.history.push("/");
          }
        } catch (error) {
          props.history.push("/login");
        }
      } else {
        props.history.push("/login");
      }
    }
  };
  useEffect(() => {
    /*  if (userDetails.Name === undefined) {
      props.history.push("/login");
    } */
    console.log(props.location.pathname);

    if (
      !(
        props.location.pathname.includes("consent") ||
        props.location.pathname.includes("home")
      )
    ) {
      handleGetUserDetails();
      setImgSource(userDetails.profilePic);
    }
  }, []);
  useEffect(() => {
    setImgSource(userDetails.profilePic);
  }, [userDetails.profilePic]);
  return (
    <Layout>
      <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
        <Menu theme="light" mode="horizontal" defaultSelectedKeys={["2"]}>
          {userDetails.Name !== undefined && (
            <React.Fragment>
              <Menu.Item key="keyHome" onClick={() => handleNavigatePage("/")}>
                Home
              </Menu.Item>
              <SubMenu
                key="subUsers"
                title="File"
                disabled={userDetails.ReadonlyUser}
              >
                <Menu.Item
                  key="keyUsers"
                  onClick={() => handleNavigatePage("/users")}
                >
                  Users
                </Menu.Item>
                <Menu.Item
                  key="keyDoctorSchedule"
                  onClick={() => handleNavigatePage("/docschedule")}
                >
                  Doctor Schedule
                </Menu.Item>
              </SubMenu>

              <Menu.Item
                key="keyAppointments"
                onClick={() => handleNavigatePage("/appointments")}
              >
                Appointments
              </Menu.Item>
            </React.Fragment>
          )}

          {userDetails.Name === undefined && (
            <React.Fragment>
              <Menu.Item
                style={{ float: "right" }}
                onClick={() => handleNavigatePage("/login")}
                key="mnuLogin"
              >
                Login
              </Menu.Item>
            </React.Fragment>
          )}
          {userDetails.Name !== undefined && (
            <React.Fragment>
              <Avatar style={{ float: "right" }} size={62} src={imgSource} />
              <SubMenu
                style={{ float: "right" }}
                key="mnuUser"
                title={userDetails.Name}
              >
                <Menu.Item key="keyLogOut" onClick={() => handleLogOut()}>
                  Logout
                </Menu.Item>

                <Menu.Item
                  key="keyProfile"
                  onClick={() => handleChangeUserProfile()}
                >
                  Profile
                </Menu.Item>
                <Menu.Item
                  key="keyChangePassword"
                  onClick={() => handleChangePassword()}
                >
                  Change Password
                </Menu.Item>
              </SubMenu>
            </React.Fragment>
          )}
        </Menu>
      </Header>

      <Content
        className="site-layout"
        style={{ padding: "0 50px", marginTop: 64 }}
      >
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 380 }}
        >
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1000,
            }}
          >
            {showSpin && (
              <Loader type="Puff" color="#00BFFF" height={80} width={80} />
            )}
          </div>
          <Routes />
        </div>
      </Content>
      <div>
        <Footer style={{ textAlign: "center" }}>
          AnywhereMed ©2021 Created by Comlogik Business Systems
        </Footer>

        <div></div>
      </div>
    </Layout>
  );
};

export default withRouter(App);
