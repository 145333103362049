import axios from "axios";

const apiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DOMAIN_DEV
    : process.env.REACT_APP_DOMAIN_PROD;
export async function PostData(payLoad) {
  try {
    var token = localStorage.getItem("tokenid");
    axios.defaults.baseURL = apiUrl;
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    let res = await axios.post(apiUrl + payLoad.endPoint, payLoad.valuestosave);

    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
    return { Status: 0, Message: error.response.data.Message };
  }
}

export async function GetListOfRecords(payLoad) {
  const url = apiUrl + "GetListOfRecords";
  var token = localStorage.getItem("tokenid");
  axios.defaults.baseURL = url;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(url, {
      params: {
        param1: payLoad.param1,
        param2: payLoad.param2,
        param3: payLoad.param3,
        param4: payLoad.param4,
        param5: payLoad.param5,
        param6: payLoad.param6,
      },
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    localStorage.removeItem("tokenid");
    // return { Status: 0, Message: error.response.data.Message };
  }
}

export async function GetListOfRecordsx(payLoad) {
  const url = apiUrl + "GetListOfRecordsx";
  var token = localStorage.getItem("tokenid");
  axios.defaults.baseURL = url;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(url, {
      params: {
        param1: payLoad.param1,
        param2: payLoad.param2,
        param3: payLoad.param3,
        param4: payLoad.param4,
        param5: payLoad.param5,
        param6: payLoad.param6,
      },
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    localStorage.removeItem("tokenid");
    // return { Status: 0, Message: error.response.data.Message };
  }
}
export async function downloadReport(payLoad) {
  const url = apiUrl + "downloadreport";
  var token = localStorage.getItem("tokenid");

  axios.defaults.baseURL = url;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    await axios({
      url: url, //your url
      method: "GET",
      responseType: "blob", // important
      params: {
        reportName: payLoad.reportName,
        param1: payLoad.param1,
        param2: payLoad.param2,
        param3: payLoad.param3,
        param4: payLoad.param4,
        isExcel: payLoad.isExcel,
        forEmail: payLoad.forEmail,
        emailAddress: payLoad.emailAddress,
        withSignature: payLoad.withSignature,
      },
    }).then((response) => {
      let newBlob;

      if (payLoad.isExcel === false) {
        newBlob = new Blob([response.data], {
          type: "application/pdf",
        });
      } else {
        newBlob = new Blob([response.data], {
          type: "application/excel",
        });
      }

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);

      const a = document.createElement("a");
      a.setAttribute("href", data);
      a.setAttribute("target", "_blank");
      a.click();
    });
  } catch (error) {
    console.log(error.response.request);
    return { Status: 0, Message: error.response.data.Message };
  }
}
